<template>
  <div
    class="avatar"
    :class="{
        'avatar_agree': isAgree && showChecked,
        'avatar_reject': isReject && showChecked,
      }"
    :style="{height: avatarSize + 'px', width: avatarSize + 'px'}"
  >
    <img v-if="src" class="avatar__photo" :src="src" alt="Photo">
    <span
      v-else
      class="avatar__placeholder"
      :style="{ fontSize: size }"
    >{{ initials }}</span>
  </div>
</template>

<script>
export default {
  props: {
    avatarSize: {
      type: Number,
      default: 36
    },
    src: {
      type: String,
      required: false
    },
    name: {
      type: String,
      default: 'C'
    },
    isAgree: {
      type: Boolean,
      default: false
    },
    isReject: {
      type: Boolean,
      default: false
    },
    showChecked: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    initials() {
      return this.name.split(' ').slice(0, 2).map(e => e[0].toUpperCase()).join('');
    },
    size() {
      return this.avatarSize / 2.22 + 'px';
    }
  }
}
</script>

<style lang="stylus" scoped>
  .avatar {
    position relative
    display flex
    justify-content center
    align-items center
    border-radius 50%

    &_reject,
    &_agree {
      border: 1.5px solid #5BBE57;

      &:before {
        content: ""
        position: absolute
        width 12px
        height 12px
        top: -2px
        right: -2px
        background-color: #5BBE57
        border-radius 50%
        z-index 1
      }

      &:after {
        content: ""
        position: absolute
        height 4.2px
        width 7px
        top: 1.5px;
        transform rotate(-45deg)

        right: 0.5px;
        background-color: transparent
        border-width: 0 0 1.5px 1.5px;
        border-color: transparent transparent #FFF #FFF
        border-style solid
        z-index 2
      }
    }

    &_reject {
      border: 1.5px solid #EA3E3E;

      &:before {
        background-color: #EA3E3E
      }

      &:after {
        height 0
        top: 3px;
        transform rotate(0)
        border-width: 0 0 1.5px;
      }
    }

    &__photo {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius 50%
      background-color: #D8D8D8
    }

    &__placeholder {
      display flex !important
      justify-content center
      align-items center
      width: 100%
      height: 100%
      color: #2F354D
      font-weight: 700;
      background-color: #D8D8D8
      border-radius 50%
    }
  }
</style>
